<template>
  <m-select
    :class="classes"
    :items="options"
    :value="value"
    :placeholder="placeholder"
    :loading="loading"
    :max-tag-text-length="maxTagTextLength"
    value-key="uid"
    item-text="title"
    return-object
    :show-search="!preventSearch"
    :clearable="clearable"
    :disabled="disabled"
    :read-only="readOnly"
    :multiple="multiple"
    :hide-border="hideBorder"
    :full-width="fullWidth"
    :show-arrow="false"
    :show-description="showDescription"
    match-trigger-width
    :hide-arrow="hideArrow"
    :auto-focus="autoFocus"
    :small="small"
    :trigger-style="triggerStyle"
    btn
    popup
    cache-items
    @search="getGoalsByName"
    @change="emitUpdate"
  />
</template>

<script>
import useDebounce from '@/composables/debounce';
import useGoals from '@/composables/goal/goals';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { RESULT } from 'shared/api/query/constants';
import { buildIconFromEntity } from 'shared/lib/icon';
import { dogma } from '@/api';
import { goalsByTitle } from '@/api/query/nebula/goal';
import { isEmpty } from 'shared/lib/object/object';
import { unionBy } from 'lodash-es';

export default {
  name: 'GoalPicker',
  props: {
    maxTagTextLength: {
      default: () => 0,
      type: Number,
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    initialItems: {
      type: Array,
      default: () => [],
    },
    preventSearch: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, Object],
      default: () => [],
    },
    triggerStyle: {
      type: Object,
      default: () => {},
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    excludedGoals: {
      type: Array,
      default: () => [],
    },
    initialLoadingGoalCycles: {
      type: Array,
      default: () => [],
    },
    hideArrow: {
      type: Boolean,
      default: true,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change'],
  setup() {
    const { debounce } = useDebounce();
    const { entityList: goals } = useGoals();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { debounce, account: loggedInUserAccount, goals };
  },
  data() {
    return {
      search: '',
      loading: false,
      selectedGoals: [],
      localGoals: [],
    };
  },
  computed: {
    excludedUids() {
      return this.excludedGoals.map((g) => g.uid);
    },
    options() {
      if (this.preventSearch) {
        return this.initialItems;
      }

      let v = this.value;
      if (!this.multiple) {
        v = [this.value];
      }

      const all = [...this.initialItems, ...this.localGoals, ...this.goals, ...v].filter((g) => g !== null);
      return unionBy(all, 'uid')
        .filter((g) => !this.excludedUids.includes(g.uid))
        .map((g) => ({ ...g, icon: buildIconFromEntity(g) }));
    },
    classes() {
      return [
        'goal-picker',
        this.fullWidth ? '-full-width' : '',
        this.hideBorder ? '-hide-border' : '',
      ];
    },
  },
  methods: {
    getGoalsByName(value) {
      if (isEmpty(value) || this.preventSearch) {
        return;
      }

      const getGoals = () => {
        this.loading = true;
        dogma.query(goalsByTitle(value, this.account.uid)).then((response) => {
          if (response.status !== 200) {
            return;
          }

          this.localGoals = response.data[RESULT];
        }).finally(() => {
          this.loading = false;
        });
      };

      this.debounce(getGoals, 500);
    },
    emitUpdate(value) {
      this.$emit('change', value);
      this.$emit('input', value);
      this.$emit('update:value', value);
    },
  },
};
</script>

<style lang="scss" type="text/scss">

</style>
