<template>
  <page-layout
    class="space-details"
  >
    <template #topBar>
      <page-top-bar
        v-if="showTopBar"
        class="_header"
        :breadcrumbs="breadcrumbs"
        :show-mobile-menu-toggle="showMobileMenuToggle"
      >
        <template
          v-if="showMyTeamsSelector"
          #left
        >
          <team-selection />
        </template>
        <template
          #after-breadcrumbs
        >
          <m-dropdown
            v-model:value="showSpaceTopBarMenu"
            :title="$t('general.actions')"
          >
            <template #overlay>
              <space-top-bar-menu
                :space="space"
                @close="showSpaceTopBarMenu = false"
              />
            </template>
          </m-dropdown>
          <m-tag
            v-if="space.archivedAt !== null"
            :style="{ paddingTop: '.2rem', marginLeft: '.4rem' }"
            icon="archive"
            :title="$t('spaceStatus.archived')"
            small
          />
        </template>
        <template #actions>
          <m-btn
            v-if="showLogout"
            small
            hide-border
            light
            @click="logout"
          >
            {{ $t('navigation.logout') }}
          </m-btn>
          <template v-if="showActions">
            <m-dropdown :title="$t('general.share')">
              <m-btn
                hide-border
                class="_action -share"
                small
              >
                {{ $t('general.share') }}
              </m-btn>
              <template #overlay>
                <m-card
                  class="_overlay"
                  padding-xs
                >
                  <m-btn
                    v-clipboard:copy="pageLink"
                    v-clipboard:error="onCopyError"
                    color="primary"
                    block
                  >
                    {{ $t('accessPolicy.copyLink') }}
                  </m-btn>
                </m-card>
              </template>
            </m-dropdown>
            <favorite-button
              v-if="(isGoalView || isUpdateView || isGoalInsightView || isUpdateFeedView) && !$store.state.breakpoint.smAndDown"
              :title-suggestion="titleSuggestion"
              :icon-suggestion="buildIconFromEntity(space)"
            />
            <m-dropdown
              v-if="(isGoalView || isUpdateView || isGoalInsightView|| isUpdateFeedView) && $store.state.breakpoint.smAndDown"
              :title="$t('general.actions')"
              placement="bottomRight"
            >
              <m-btn
                hide-border
                class="_action"
                small
                icon="ellipsis"
                fab
              />
              <template #overlay>
                <m-card
                  list
                  no-padding
                  class="space-detail-more-overlay"
                >
                  <favorite-button
                    v-if="(isGoalView || isUpdateView || isGoalInsightView|| isUpdateFeedView) && $store.state.breakpoint.smAndDown"
                    :title-suggestion="titleSuggestion"
                    :icon-suggestion="buildIconFromEntity(space)"
                  />
                </m-card>
              </template>
            </m-dropdown>
          </template>
        </template>
      </page-top-bar>
    </template>
    <scroll-container
      :style="{ width: `${containerWidth}px` }"
      :no-padding-bottom="isGoalView"
    >
      <m-content
        class="_content"
        padding-x="layout"
      >
        <div
          :style="{ width: `${contentWidth}px` }"
        >
          <div
            class="_header"
          >
            <editable-page-header
              class="_title"
              :title="title"
              :icon-types="iconTypes"
              :icon="icon"
              :show-icon="!isEmptyIcon(icon) || emojiAdded"
              :placeholder="$t('list.noTitle')"
              :disabled="!canEdit"
              size="small"
              @change-title="updateTitle"
              @change-icon="updateIcon"
            >
              <template #actions>
                <div class="_header-actions">
                  <m-btn
                    v-if="isEmptyIcon(icon) && !emojiAdded && canEdit"
                    hide-border
                    class="_item"
                    icon="smile"
                    light
                    small
                    @click="addEmoji"
                  >
                    {{ $t('page.addEmoji') }}
                  </m-btn>
                  <m-btn
                    v-if="isEmptyDescription && !descriptionAdded && canEdit"
                    hide-border
                    class="_item"
                    icon="align-left"
                    light
                    small
                    @click="addDescription"
                  >
                    {{ $t('spaceDetails.addDescription') }}
                  </m-btn>
                </div>
              </template>
            </editable-page-header>
            <div class="_editor-content -condensed">
              <m-editor
                v-if="!isEmptyDescription || descriptionAdded"
                ref="editor"
                :initial-value="description"
                :disabled="!canEdit"
                :placeholder="$t('spaceDetails.addADescription')"
                :allowed-content="allowedContent"
                light-placeholder
                @input="updateDescription"
                @blur="blurDescription"
              />
            </div>
          </div>
          <sub-menu-tabs
            :items="localTabs"
            small
            light
            class="_tabs"
          />
        </div>
      </m-content>

      <space-goals
        v-if="isGoalView"
        :property="property"
        :space="space"
      />
      <m-content padding-x="layout">
        <space-updates
          v-if="isUpdateView"
          :property="property"
          :space="space"
        />
        <space-update-feed
          v-if="isUpdateFeedView"
          :property="property"
          :space="space"
        />
        <space-users
          v-if="isUserView"
          :property="property"
          :space="space"
        />
      </m-content>
      <space-goal-insights
        v-if="isGoalInsightView && goalInsightAccess"
        :property="property"
        :space="space"
      />
    </scroll-container>
  </page-layout>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import MEditor from '@/components/editor/MEditor.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import SpaceGoalInsights from '@/components/spaces/SpaceGoalInsights.vue';
import SpaceGoals from '@/components/spaces/SpaceGoals.vue';
import SpaceTopBarMenu from '@/components/spaces/SpaceTopBarMenu.vue';
import SpaceUpdateFeed from '@/components/spaces/SpaceUpdateFeed.vue';
import SpaceUpdates from '@/components/spaces/SpaceUpdates.vue';
import SpaceUsers from '@/components/spaces/SpaceUsers.vue';
import SubMenuTabs from '@/components/SubMenuTabs.vue';
import TeamSelection from '@/components/msteams/TeamSelection.vue';
import useAccess from '@/composables/access/access';
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useSpaces from '@/composables/space/spaces';
import useViewNavigator from '@/composables/saved-views/navigator';
import {
  GOAL_FEED_VIEW,
  GOAL_INSIGHT_VIEW,
  GOAL_VIEW,
  UPDATE_VIEW,
  USER_VIEW,
  VIEW,
} from '@/route-params';
import { SIDEBAR_SERVICE } from '@/lib/constants';
import { accessGroupFlag, accessPolicyType, editorNodeType, featureFlag, iconType, routeName } from 'shared/constants.json';
import { buildIconFromEntity, isEmptyIcon, replaceIconOnEntity } from 'shared/lib/icon';
import { getQueryParam } from '@/lib/route';
import { inject } from 'vue';
import { isEmpty } from 'shared/lib/object/object';
import { isZero } from '@/lib/editor/editor';
import { logCatch } from '@/lib/logger/logger';
import { mapActions } from 'vuex';

export default {
  name: 'SpaceDetailsTemplate',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    property: {
      type: Object,
      default: () => null,
    },
    space: {
      type: Object,
      default: () => null,
    },
    showTopBar: {
      type: Boolean,
      default: false,
    },
    showMyTeamsSelector: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
    showLogout: {
      type: Boolean,
      default: false,
    },
    showMobileMenuToggle: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SpaceTopBarMenu,
    ScrollContainer,
    PageLayout,
    SpaceUpdateFeed,
    PageTopBar,
    SubMenuTabs,
    EditablePageHeader,
    SpaceGoalInsights,
    FavoriteButton,
    SpaceUpdates,
    SpaceUsers,
    SpaceGoals,
    TeamSelection,
    MEditor,
  },
  setup() {
    const { userHasRight } = useAccess();
    const { userLang } = useLoggedInUser();
    const { linkToRoute } = useViewNavigator();

    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { debounce } = useDebounce({ onBeforeUnmountFlush: true });
    const spacesSvc = useSpaces();

    const { containerWidth, contentWidth } = inject(SIDEBAR_SERVICE);

    const allowedContent = [
      editorNodeType.bulletList,
      editorNodeType.orderedList,
    ];
    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.file);
    }

    return {
      debounce,
      userHasRight,
      linkToRoute,
      account: loggedInUserAccount,
      spacesSvc,
      userLang,
      allowedContent,
      containerWidth,
      contentWidth,
    };
  },
  data() {
    return {
      dataLoading: false,
      emojiAdded: false,
      descriptionAdded: false,
      titleCache: '',
      description: null,
      showSpaceTopBarMenu: false,
      GOAL_VIEW,
      GOAL_INSIGHT_VIEW,
      USER_VIEW,
      UPDATE_VIEW,
      routeName,
      iconTypes: [iconType.emoji, iconType.icon, iconType.custom],
    };
  },
  computed: {
    localTabs() {
      return this.tabs.map((t) => ({
        ...t,
        active: t.view === this.currentView,
      }));
    },
    titleSuggestion() {
      switch (true) {
        case this.isGoalView:
          return `${this.title} - ${this.account.goalSettings.featureNamePlural}`;
        case this.isUpdateView:
          return `${this.title} - ${this.$t('navigation.updatesExplorer')}`;
        case this.isGoalInsightView:
          return `${this.title} - ${this.$t('navigation.goalInsights')}`;
        case this.isUpdateFeedView:
          return `${this.title} - ${this.$t('navigation.feed')}`;
        default:
          return '';
      }
    },
    title: {
      get() {
        if (!this.allowEdit) {
          return this.space.title;
        }

        if (this.titleCache === '') {
          return this.space.title;
        }
        return this.titleCache;
      },
      set(value) {
        this.titleCache = value;
      },
    },
    icon() {
      return buildIconFromEntity(this.space);
    },
    isEmptyDescription() {
      if (this.description === null || this.description === undefined) {
        return true;
      }

      return isZero(this.description);
    },
    spaceTitle() {
      return this.space.title;
    },
    spaceDescription() {
      return this.space.description;
    },
    canEdit() {
      return this.allowEdit && [accessPolicyType.full, accessPolicyType.write].includes(this.space.accessRight);
    },
    currentView() {
      const v = getQueryParam(this.$route, VIEW);
      if (isEmpty(v)) {
        return GOAL_VIEW;
      }
      return v;
    },
    activeTab() {
      const filtered = this.tabs.filter((t) => t.view === this.currentView);
      if (filtered.length === 0) {
        return null;
      }
      return filtered[0];
    },
    isGoalView() {
      return this.currentView === GOAL_VIEW;
    },
    goalInsightAccess() {
      return this.userHasRight([accessGroupFlag.goalDashboardView]);
    },
    isGoalInsightView() {
      return this.currentView === GOAL_INSIGHT_VIEW;
    },
    isUserView() {
      return this.currentView === USER_VIEW;
    },
    isUpdateView() {
      return this.currentView === UPDATE_VIEW;
    },
    isUpdateFeedView() {
      return this.currentView === GOAL_FEED_VIEW;
    },
    breadcrumbs() {
      const parents = this.parentSpaces.map((s) => ({
        title: s.title,
        icons: [{ value: buildIconFromEntity(s), size: 16 }],
        to: this.linkToRoute(undefined, routeName.spaceDetails, GOAL_VIEW, s.uid).to,
        disabled: false,
      })).reverse();

      return [
        ...parents,
        {
          title: this.space.title,
          icons: [{ value: buildIconFromEntity(this.space), size: 16 }],
          onClick: () => { this.showSpaceTopBarMenu = true; },
        },
      ];
    },
    pageLink() {
      return window.location;
    },
    parentSpaces() {
      const addParents = (acc, space) => {
        if (space.parents.length === 0) {
          return acc;
        }
        const parent = this.spacesSvc.selectSingle(space.parents[0].uid);
        if (parent === undefined || parent.archivedAt !== null) {
          return acc;
        }
        return addParents([...acc, parent], parent);
      };
      return addParents([], this.space);
    },
  },
  methods: {
    buildIconFromEntity,
    isEmptyIcon,
    ...mapActions([
      'logout',
    ]),
    updateIcon(icon) {
      if (isEmptyIcon(icon)) {
        this.emojiAdded = false;
      }
      return this.update(replaceIconOnEntity({ ...this.space }, icon));
    },
    updateTitle(value) {
      const updateFunc = () => {
        if (JSON.stringify(value) === JSON.stringify(this.title)) {
          return;
        }
        this.update({ ...this.space, title: value });
      };

      this.debounce(updateFunc, 500);
    },
    updateDescription(value) {
      if (JSON.stringify(value) === JSON.stringify(this.description)) {
        return;
      }
      this.description = value;
      const updateFunc = () => {
        this.update({ ...this.space, description: value });
      };

      this.debounce(updateFunc, 500);
    },
    blurDescription() {
      if (this.isEmptyDescription) {
        this.descriptionAdded = false;
      }
    },
    addEmoji() {
      this.emojiAdded = true;
    },
    addDescription() {
      this.descriptionAdded = true;
      this.$nextTick(() => {
        this.$refs.editor.focus();
      });
    },
    onCopyError() {
      this.$showSnackbar({ color: 'error', message: this.$t('error.duringCopying') });
    },
    update(entity) {
      if (!this.canEdit) {
        return;
      }
      this.spacesSvc.updateSpace(entity, { ignoreResponse: false }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    initRoute() {
      if (this.$router.currentRoute.value.name !== routeName.spaceDetails) {
        return;
      }

      if (this.tabs.length > 0) {
        this.$router.replace({ ...this.$route, query: { ...this.$route.query, view: this.tabs[0].view } });
      }
    },
  },
  watch: {
    currentView(newVal) {
      if (newVal === '') {
        this.initRoute();
      }
    },
    spaceTitle(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.titleCache = newVal;
      }
    },
    spaceDescription(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.description = newVal;
        if (this.isEmptyDescription) {
          this.descriptionAdded = false;
        }
      }
    },
  },
  created() {
    if (this.currentView === '') {
      this.initRoute();
    }
    this.description = this.space.description;
    this.descriptionAdded = !this.isEmptyDescription;
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .space-details {
    ._header {
      position: sticky;
      left: 0;
    }

    ._content {
      position: sticky;
      left: 0;
      z-index: 1;

      ._title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        ._header-actions {
          display: flex;
        }
      }

      ._editor-content {
        margin-top: -1rem;
        font-size: $font-size-1;
        color: $font-color-secondary;

        &.-condensed {
          p {
            min-height: 0;
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }

      ._tabs {
        margin: 2rem 0;
        overflow: auto;
      }
    }
  }

  .space-detail-more-overlay {
    min-width: 20rem;
  }

</style>
