<template>
  <div class="schedule-detail-page-header">
    <page-top-bar
      :open-in-modal="modal"
      :breadcrumbs="breadcrumbs"
    >
      <template #left>
        <m-btn
          v-if="modal"
          hide-border
          small
          icon="open-as-page"
          :icon-color="$colors.grey.base"
          :to="detailsPage"
        >
          {{ $t('pageHeader.openAsPage') }}
        </m-btn>
        <breadcrumbs
          v-else
          :breadcrumbs="breadcrumbs"
        />
      </template>
      <template #actions>
        <share-dropdown
          v-if="!$store.state.breakpoint.smAndDown"
          :access-policy="notification.accessPolicy"
          :access-right="minAccessRight"
          :creator="notification.creator"
          entity-type="notification"
          icon="lock"
          :creator-description="$t('scheduleDetailPageHeader.creatorDescription')"
          :page-link="pageLink"
          :disabled="!canEdit"
          :loading="updateLoading"
          :on-update="updateAccessPolicy"
        >
          <template #after-access-policy>
            <access-policy-scope-row
              icon="mail"
              :access-type="accessPolicyType.read"
              disabled
            >
              <template #name>
                {{ $t('scheduleDetailTop.allRecipients') }}
              </template>
              <template #description>
                {{ $t('scheduleDetailTop.allRecipientsDescription') }}
              </template>
            </access-policy-scope-row>
          </template>
        </share-dropdown>
        <favorite-button
          v-if="!$store.state.breakpoint.smAndDown"
          :title-suggestion="notification.title"
          :entity-id="notification.uid"
          :route-name="updateScheduleDetails"
          :route-params="{notificationId: notification.uid}"
          :route-query="{}"
        />
        <m-dropdown
          v-if="$store.state.breakpoint.smAndDown || canEdit"
          v-model:value="showMenu"
          :title="$t('general.actions')"
          placement="bottomRight"
        >
          <m-btn
            hide-border
            class="_action"
            small
            icon="ellipsis"
            fab
            @click="showMenu = true"
          />
          <template #overlay>
            <m-card
              list
              no-padding
              class="_overlay"
            >
              <share-dropdown
                v-if="$store.state.breakpoint.smAndDown"
                :access-policy="notification.accessPolicy"
                :creator="notification.creator"
                entity-type="notification"
                icon="lock"
                :page-link="pageLink"
                :disabled="!canEdit"
                :loading="updateLoading"
                :on-update="updateAccessPolicy"
              />
              <favorite-button
                v-if="$store.state.breakpoint.smAndDown"
                :title-suggestion="notification.title"
                :entity-id="notification.uid"
                :route-name="updateScheduleDetails"
                :route-params="{notificationId: notification.uid}"
                :route-query="{}"
              />
              <m-card-item
                v-for="item in menuItems"
                :key="item.name"
                :icon="item.icon"
                :loading="item.loading"
                @click="item.onClick(notification)"
              >
                {{ item.name }}
              </m-card-item>
            </m-card>
          </template>
        </m-dropdown>
      </template>
    </page-top-bar>
  </div>
</template>

<script>
import AccessPolicyScopeRow from '@/components/access-policy/AccessPolicyScopeRow.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ShareDropdown from '@/components/access-policy/ShareDropdown.vue';
import useUpdateSchedules from '@/composables/update-schedules/update-schedules';
import { accessPolicyType, routeName } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { logCatch } from '@/lib/logger/logger';
import { mapActions } from 'vuex';

export default {
  name: 'ScheduleDetailTop',
  props: {
    notification: {
      type: Object,
      required: true,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AccessPolicyScopeRow,
    Breadcrumbs,
    PageTopBar,
    ShareDropdown,
    FavoriteButton,
  },
  setup() {
    const { deleteLoading, deleteSchedule, updateScheduleAccessPolicy, updateLoading } = useUpdateSchedules();
    return { deleteLoading, deleteSchedule, updateScheduleAccessPolicy, updateLoading };
  },
  data() {
    return {
      accessPolicyType,
      showMenu: false,
      pageLink: `${window.location.origin}/#/check-in-schedules/${this.notification.uid}`,
      updateScheduleDetails: routeName.updateScheduleDetails,
    };
  },
  computed: {
    minAccessRight() {
      return this.notification.accessRight !== accessPolicyType.disabled ? this.notification.accessRight : accessPolicyType.read;
    },
    menuItems() {
      return [
        {
          name: this.$t('general.delete'),
          onClick: this.delete,
          loading: this.deleteLoading,
          disabled: this.disabled,
          icon: 'delete',
        },
      ];
    },
    breadcrumbs() {
      return [
        {
          title: this.$t('navigation.updatesExplorer'),
          disabled: false,
          to: { name: routeName.updatesExplorer },
        },
        {
          title: this.$t('updatesList.schedules'),
          disabled: false,
          to: { name: routeName.updateSchedules },
        },
        {
          title: this.notification.title === '' ? '-' : this.notification.title,
          icons: [{ value: buildIconFromEntity(this.notification) }],
          disabled: true,
        },
      ];
    },
    detailsPage() {
      return {
        name: routeName.updateScheduleDetails,
        params: { notificationId: this.notification.uid },
      };
    },
  },
  methods: {
    ...mapActions(['updateEntityV2']),
    updateAccessPolicy(val) {
      this.updateScheduleAccessPolicy(val, this.notification).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    delete(notification) {
      const deleteMethod = () => {
        this.deleteSchedule(notification).then(() => {
          this.$router.push({ name: routeName.updateSchedules });
        }).catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
      };

      this.$confirm({
        title: this.$t('notificationEditorCard.deletePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
