<template>
  <div class="detail-header">
    <page-top-bar :open-in-modal="modal || sidePeek">
      <template #left>
        <div
          v-if="modal || sidePeek"
          class="_btn"
        >
          <m-tooltip placement="bottom">
            <m-btn
              hide-border
              small
              fab
              icon="double-right"
              :icon-color="$colors.grey.base"
              @click="$emit('close')"
            />
            <template #title>
              {{ $t("detailHeader.close") }}
              <br><span class="_shortcut">Escape</span>
            </template>
          </m-tooltip>
          <m-tooltip
            v-if="!isPublishedView"
            placement="bottom"
          >
            <m-btn
              hide-border
              small
              fab
              icon="open-as-page"
              :icon-color="$colors.grey.base"
              @click="detailsPage"
            />
            <template #title>
              {{ $t('detailHeader.openAsPage') }}
            </template>
          </m-tooltip>
        </div>
        <m-dropdown
          v-if="!isPublishedView && (sidePeek || modal) && !$store.state.breakpoint.smAndDown"
          id="view-mode-btn"
          v-model:value="showViewModeMenu"
          class="_view-mode-btn"
          :title="$t('peekView.peekModes')"
        >
          <m-tooltip placement="bottom">
            <m-btn
              fab
              small
              hide-border
              :icon="viewModeIcon"
              :icon-color="$colors.grey.base"
            />
            <template #title>
              {{ $t('detailHeader.switchPeekMode') }}
            </template>
          </m-tooltip>
          <template #overlay>
            <m-card
              no-padding
              list
            >
              <m-card-item
                icon="side-peek"
                @click="updateViewModeSetting(viewMode.sidePeek)"
              >
                {{ $t('peekView.sidePeek') }}
                <template #right>
                  <m-icon
                    v-if="goalViewMode === viewMode.sidePeek"
                    type="check"
                  />
                </template>
              </m-card-item>
              <m-card-item
                icon="center-peek"
                @click="updateViewModeSetting(viewMode.centerPeek)"
              >
                {{ $t("peekView.centerPeek") }}
                <template #right>
                  <m-icon
                    v-if="goalViewMode === viewMode.centerPeek"
                    type="check"
                  />
                </template>
              </m-card-item>
            </m-card>
          </template>
        </m-dropdown>
        <goal-breadcrumbs
          v-if="!sidePeek && !modal"
          :goal="goal"
          :modal="modal"
          :slice-amount="goal.parents.length > 1 ? 1 : 2"
          :read-only="readOnly"
        />
      </template>
      <template #actions>
        <div
          v-if="!isPublishedView"
          class="_actions"
        >
          <share-dropdown
            v-if="!$store.state.breakpoint.smAndDown"
            :access-policy="goal.accessPolicy"
            :creator="selectSingleUser(goal.creator?.uid)"
            entity-type="goal"
            :icon="isPublic ? 'global' : 'lock'"
            :access-types="[
              accessPolicyType.read,
              accessPolicyType.comment,
              accessPolicyType.write,
              accessPolicyType.full,
            ]"
            :page-link="pageLink"
            :disabled="goal.accessRight !== accessPolicyType.full"
            :loading="accessPolicyLoading"
            :on-update="updateAccessPolicy"
          />
          <favorite-button
            v-if="!$store.state.breakpoint.smAndDown"
            :title-suggestion="goal.title"
            :icon-suggestion="buildIconFromEntity(goal)"
            :entity-id="goal.uid"
            :route-name="GOAL_DETAILS"
            :route-params="{ goalId: goal.uid }"
            :route-query="{}"
          />
          <m-dropdown
            v-model:value="furtherActions"
            :title="$t('general.actions')"
            placement="bottomRight"
          >
            <m-btn
              data-cy="goal-header-more-btn"
              hide-border
              class="_action"
              small
              icon="ellipsis"
              fab
            />
            <template #overlay>
              <m-card
                list
                no-padding
                class="_overlay"
              >
                <share-dropdown
                  v-if="$store.state.breakpoint.smAndDown"
                  :access-policy="goal.accessPolicy"
                  :creator="selectSingleUser(goal.creator?.uid)"
                  entity-type="goal"
                  :icon="isPublic ? 'global' : 'lock'"
                  :access-types="[
                    accessPolicyType.read,
                    accessPolicyType.comment,
                    accessPolicyType.write,
                    accessPolicyType.full,
                  ]"
                  :page-link="pageLink"
                  :disabled="goal.accessRight !== accessPolicyType.full"
                  :loading="accessPolicyLoading"
                  :on-update="updateAccessPolicy"
                />
                <favorite-button
                  v-if="$store.state.breakpoint.smAndDown"
                  :title-suggestion="goal.title"
                  :icon-suggestion="buildIconFromEntity(goal)"
                  :entity-id="goal.uid"
                  :route-name="GOAL_DETAILS"
                  :route-params="{ goalId: goal.uid }"
                  :route-query="{}"
                />
                <m-card-item
                  v-for="item in menuItems"
                  :key="item.name"
                  :icon="item.icon"
                  :emoji="item.emoji"
                  :tooltip="item.tooltip"
                  :tooltip-placement="item.tooltipPlacement"
                  :loading="item.loading"
                  :disabled="item.disabled"
                  @click="item.onClick"
                >
                  <m-switch
                    v-if="item.isSwitch"
                    :value="item.checked"
                    :disabled="item.disabled"
                    small
                    :label="item.name"
                    class="_switch"
                  />
                  <div v-else>
                    {{ item.name }}
                  </div>
                </m-card-item>
              </m-card>
              <m-card
                v-if="showMenu === 'add-to-grid-editor'"
                padding-xs
              >
                <add-to-grid-editor
                  :items="[goal]"
                  @close="hideMenu"
                />
              </m-card>
            </template>
          </m-dropdown>
        </div>
      </template>
    </page-top-bar>
  </div>
</template>

<script>
import AddToGridEditor from '@/components/custom-grid/AddToGridEditor.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import GoalBreadcrumbs from '@/components/breadcrumbs/GoalBreadcrumbs.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ShareDropdown from '@/components/access-policy/ShareDropdown.vue';
import useAccess from '@/composables/access/access';
import useDeleteGoal from '@/composables/goal/delete-goal';
import useGoalSubscription from '@/composables/goal/goal-subscription';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import usePublishedViewLogin from '@/composables/published-view/login';
import useStatusAutoUpdate from '@/composables/goal/status-auto-update';
import useUsers from '@/composables/user/users';
import { GOAL_DETAILS } from '@/route-names';
import {
  accessPolicyType,
  goalProgressMeasurement,
  goalViewMode as viewMode,
} from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { composedAccessPolicy } from '@/lib/access-policy-linking';
import { computed } from 'vue';
import { getHighestAccessRight } from '@/lib/access-policy';
import { logCatch } from '@/lib/logger/logger';
import { rgbaToHex } from 'shared/lib/color';

export default {
  name: 'DetailHeader',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    sidePeek: {
      type: Boolean,
      default: false,
    },
    routeAfterDelete: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['deleted', 'go-to-details', 'close'],
  components: {
    AddToGridEditor,
    GoalBreadcrumbs,
    PageTopBar,
    FavoriteButton,
    ShareDropdown,
  },
  setup() {
    const goalSubscriptionSvc = useGoalSubscription();
    const { deleteLoading, showDeleteGoalModal } = useDeleteGoal();
    const { accountHasFeature } = useAccess();
    const { updateSingle, deleteSingle } = useGoals();
    const { loggedInUser } = useLoggedInUser();
    const { personalAppSettings, updateSingle: updatePersonalAppSettings } = usePersonalAppSettings(loggedInUser);
    const { automaticStatusUpdateLoading, toggleStatusUpdate } = useStatusAutoUpdate();
    const { selectSingle: selectSingleUser } = useUsers();
    const { isPublishedView } = usePublishedViewLogin();
    return {
      accountHasFeature,
      updateSingle,
      deleteSingle,
      goalSubscriptionSvc,
      subscriptionLoading: goalSubscriptionSvc.createLoading,
      loggedInUser,
      automaticStatusUpdateLoading,
      toggleStatusUpdate,
      deleteLoading,
      showDeleteGoalModal,
      selectSingleUser,
      isPublishedView,
      updatePersonalAppSettings,
      personalAppSettings,
      viewMode,
      goalViewMode: computed(() => personalAppSettings.value.goalViewMode),
    };
  },
  data() {
    return {
      rgbaToHex,
      accessPolicyType,
      accessPolicyLoading: false,
      showVisibilityModal: false,
      pageLink: `${window.location.origin}/#/goals/${this.goal.uid}`,
      shareDropdownVisible: false,
      furtherActions: false,
      showMenu: '',
      showViewModeMenu: false,
      GOAL_DETAILS,
    };
  },
  computed: {
    accountAccess() {
      // FIXME: filter((l) => l !== undefined) is the effect of wanting to be optimistic and not being able to denormalize create elements
      const vias = [this.goal.accessPolicy, ...this.goal.accessPolicy.links.filter((l) => l !== undefined).map((link) => composedAccessPolicy(link))];
      return getHighestAccessRight(vias.map((ap) => ap.accountAccess));
    },
    isPublic() {
      return this.accountAccess !== accessPolicyType.disabled;
    },
    menuItems() {
      const res = [];
      if (
        this.goal.progressMeasurement === goalProgressMeasurement.alignedItems
      ) {
        res.push({
          name: this.$t('goal.detailHeader.disableStatusAutoUpdate'),
          icon: 'sync',
          tooltip: this.$t('goal.detailHeader.statusUpdateTooltip'),
          tooltipPlacement: 'left',
          loading: this.automaticStatusUpdateLoading,
          isSwitch: true,
          disabled: !this.canEdit,
          checked: !this.goal.disableStatusAutoUpdate,
          onClick: () => this.toggleStatusUpdate(this.goal),
        });
      }

      res.push({
        name: this.$t('goal.detailHeader.notification'),
        icon: 'notification',
        loading: this.subscriptionLoading,
        isSwitch: true,
        checked: this.notificationsEnabled,
        onClick: this.triggerSubscription,
      });

      res.push({
        name: this.$t('goal.detailHeader.addToDashboard'),
        emoji: 'ReconciliationOutlined',
        onClick: this.triggerAddToDashboard,
      });

      if (this.canEdit) {
        res.push({
          name: this.$t('general.delete'),
          icon: 'delete',
          loading: this.deleteLoading,
          onClick: () => this.showDeleteGoalModal({
            goals: [this.goal],
            redirect: this.routeAfterDelete,
          })
            .then(() => {
              this.closeModal();
            })
            .catch(() => {}),
        });
      }

      return res;
    },
    notificationsEnabled() {
      const sub = this.goal.subscriptions.filter(
        (s) => s.user.uid === this.loggedInUser.uid,
      );
      if (sub.length === 0) {
        return false;
      }

      return sub[0].active;
    },
    canEdit() {
      if (this.readOnly) {
        return false;
      }

      return [accessPolicyType.full, accessPolicyType.write].includes(
        this.goal.accessRight,
      );
    },
    viewModeIcon() {
      if (this.goalViewMode === viewMode.centerPeek) {
        return 'center-peek';
      }
      return 'side-peek';
    },
  },
  methods: {
    buildIconFromEntity,
    hideMenu() {
      this.showMenu = '';
      this.furtherActions = false;
    },
    triggerAddToDashboard() {
      this.showMenu = 'add-to-grid-editor';
    },
    triggerSubscription() {
      const mySubscription = this.goal.subscriptions.filter(
        (sub) => sub.user.uid === this.loggedInUser.uid,
      );
      if (mySubscription.length === 0) {
        this.createSubscription({
          active: true,
          user: { uid: this.loggedInUser.uid },
        });
        return;
      }

      this.updateSubscription({
        uid: mySubscription[0].uid,
        active: !mySubscription[0].active,
      });
    },
    closeModal() {
      this.$emit('deleted');
    },
    detailsPage() {
      this.$emit('go-to-details', {
        to: {
          name: GOAL_DETAILS,
          params: { goalId: this.goal.uid },
        },
      });
    },
    updateAccessPolicy(val) {
      this.accessPolicyLoading = true;
      const entity = {
        ...this.goal,
        accessPolicy: val,
      };
      this.updateSingle(entity, { optimistic: false }).then((data) => {
        if (data === undefined) {
          this.deleteSingle(entity.uid, { commitToRemote: false });
          this.$router.push('/goals');
        }
      }).finally(() => {
        this.accessPolicyLoading = false;
      });
    },
    createSubscription(entity) {
      this.goalSubscriptionSvc
        .createSubscription({ ...entity, goal: { uid: this.goal.uid } })
        .catch(
          logCatch(() => {
            this.$showSnackbar({
              color: 'error',
              message: this.$t('general.error'),
            });
          }),
        );
    },
    updateSubscription(entity) {
      this.goalSubscriptionSvc.updateSubscription(entity).catch(
        logCatch(() => {
          this.$showSnackbar({
            color: 'error',
            message: this.$t('general.error'),
          });
        }),
      );
    },
    updateViewModeSetting(viewMode) {
      this.updatePersonalAppSettings({
        uid: this.personalAppSettings.uid,
        goalViewMode: viewMode,
      });
      this.showViewModeMenu = false;
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
.detail-header {
  ._btn {
    display: flex;
    padding-right: 0.4rem;
    margin-right: 0.4rem;
  }

  ._view-mode-btn {
    border-left: 1px solid $border-color;
    padding-left: 0.8rem;
  }
}

._overlay {
  min-width: 30rem;

  ._switch {
    width: 100%;
    margin: 0;
  }
}

._actions {
  display: flex;
  align-items: center;
}

._shortcut {
  font-size: $font-size-2;
  color: $grey-absolute-base;
}
</style>
