<template>
  <space-selector
    v-if="isTypeSpace"
    ref="input"
    :value="selectedSpaces"
    :read-only="readOnly"
    :disabled="disabled"
    :property-label="property.label"
    :hide-border="hideBorder"
    :hide-hover="hideHover"
    :hide-placeholder="hidePlaceholder"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? icon : ''"
    :full-width="fullWidth"
    :small="small"
    :m-style="mStyle"
    hide-arrow
    :restrict-foreign-entity-selection="restrictForeignEntitySelection"
    :placement="popup ? 'onTopLeft' : 'bottomCenter'"
    :match-trigger-width="matchTriggerWidth"
    :wrap="wrap"
    :auto-focus="autoFocus"
    multiple
    @input="changeSelectedSpaces"
    @close="exitSpace"
  />
  <m-select
    v-else-if="isTypeOptions"
    ref="input"
    :placeholder-icon="showPlaceholderIcon ? icon : ''"
    :value="selectedOptions"
    :placeholder="placeholder"
    :hide-placeholder="hidePlaceholder"
    :items="options"
    :nowrap="!wrap"
    :m-style="mStyle"
    :hide-hover="hideHover"
    item-text="label.de"
    multiple
    :small="small"
    value-key="uid"
    return-object
    hide-arrow
    :clearable="clearable"
    :read-only="readOnly"
    :disabled="disabled"
    :hide-border="hideBorder"
    :full-width="fullWidth"
    :popup="popup"
    :auto-focus="autoFocus"
    automatic-color
    show-search
    keep-open-on-click
    show-description
    :match-trigger-width="matchTriggerWidth"
    tags
    @change="changeSelectedOptions"
    @close="exitOptions"
  />
  <m-select
    v-if="isTypeSingleSelect"
    ref="input"
    :value="selectedOption"
    :m-style="mStyle"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? icon : ''"
    :hide-placeholder="hidePlaceholder"
    :items="options"
    item-text="label.de"
    value-key="uid"
    return-object
    hide-arrow
    :nowrap="!wrap"
    :hide-hover="hideHover"
    :clearable="clearable"
    :read-only="readOnly"
    :disabled="disabled"
    :hide-border="hideBorder"
    :full-width="fullWidth"
    show-search
    :popup="popup"
    :auto-focus="autoFocus"
    :small="small"
    hide-selected-values
    show-description
    :match-trigger-width="matchTriggerWidth"
    tags
    @change="changeSelectedOption"
    @close="exitSingleSelect"
  />
  <number-input
    v-else-if="isTypeNumber"
    ref="input"
    :m-style="mStyle"
    :value="numberValue"
    :title="propertyLabel"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? icon : ''"
    :hide-placeholder="hidePlaceholder"
    :hide-hover="hideHover"
    :hide-border="hideBorder"
    :read-only="readOnly"
    :small="small"
    :number-format="numberFormat"
    :disabled="disabled"
    :match-trigger-width="matchTriggerWidth"
    :auto-focus="autoFocus"
    :full-width="fullWidth"
    @change="changeNumber"
    @blur="exitNumber"
  />
  <text-input
    v-else-if="isTypeText"
    ref="input"
    :value="textValue"
    :title="propertyLabel"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? icon : ''"
    :hide-placeholder="hidePlaceholder"
    :hide-border="hideBorder"
    :read-only="readOnly"
    :hide-hover="hideHover"
    :disabled="disabled"
    :match-trigger-width="matchTriggerWidth"
    :auto-focus="autoFocus"
    :full-width="fullWidth"
    :small="small"
    :m-style="mStyle"
    :wrap="wrap"
    @change="changeText"
    @blur="exitText"
  />
  <url-input
    v-else-if="isTypeUrl"
    ref="input"
    :value="urlValue"
    :title="propertyLabel"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? icon : ''"
    :hide-placeholder="hidePlaceholder"
    :hide-border="hideBorder"
    :read-only="readOnly"
    :hide-hover="hideHover"
    :disabled="disabled"
    :match-trigger-width="matchTriggerWidth"
    :auto-focus="autoFocus"
    :full-width="fullWidth"
    :small="small"
    :m-style="mStyle"
    :wrap="wrap"
    :disable-links="disableLinks"
    @change="changeUrl"
    @blur="exitUrl"
  />
  <m-date-picker
    v-else-if="isTypeDate"
    ref="input"
    :value="fromISO(dateValue)"
    :small="small"
    :m-style="mStyle"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? icon : ''"
    :clearable="clearable"
    :date-time="DateTime"
    :read-only="readOnly"
    :disabled="disabled"
    :hide-border="hideBorder"
    :hide-hover="hideHover"
    :hide-placeholder="hidePlaceholder"
    :full-width="fullWidth"
    :auto-focus="autoFocus"
    :locale="userLang"
    include-time
    @change="changeDate"
    @close="exitDate"
  />
  <user-picker
    v-else-if="isTypeUser"
    ref="input"
    :m-style="mStyle"
    :value="selectedUsers"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? icon : ''"
    :hide-placeholder="hidePlaceholder"
    :read-only="readOnly"
    :disabled="disabled"
    :hide-border="hideBorder"
    :hide-hover="hideHover"
    :small="small"
    :nowrap="!wrap"
    :full-width="fullWidth"
    :user-details-page="readOnly ? '' : PROFILE"
    :popup="popup"
    :match-trigger-width="matchTriggerWidth"
    hide-arrow
    :restrict-foreign-entity-selection="restrictForeignEntitySelection"
    :auto-focus="autoFocus"
    @change="changeSelectedUser"
    @close="exitUser"
  />
  <lookup-value
    v-else-if="isTypeLookup"
    :hide-border="hideBorder"
    :m-style="mStyle"
    :placeholder="placeholder"
    :small="small"
    :values="propertyValues"
    :hide-placeholder="hidePlaceholder"
    :lookup-property="property"
    :full-width="fullWidth"
    :wrap="wrap"
    show-user-name
    show-empty-value
  />
</template>

<script>
import LookupValue from '@/components/property/LookupValue.vue';
import NumberInput from '@/components/property/NumberInput.vue';
import SpaceSelector from '@/components/spaces/SpaceSelector.vue';
import TextInput from '@/components/property/TextInput.vue';
import UrlInput from '@/components/property/UrlInput.vue';
import UserPicker from '@/components/UserPicker.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import useResourceSettings from '@/composables/logged-in-user-account/resource-settings';
import { DateTime } from 'luxon';
import { PROFILE } from '@/route-names';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed } from 'vue';
import { emptyValue, iconByType } from '@/lib/property';
import { equalP } from 'shared/lib/array/array';
import { fromISO, toISO } from 'shared/lib/time';
import { isEqual } from 'lodash-es';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { mStyleProps } from 'shared/lib/m-style-props';
import { propertyType } from 'shared/constants.json';
import { sortedPropertyElements } from '@/lib/props';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'PropertyFormItem',
  props: {
    ...mStyleProps,
    property: {
      type: Object,
      required: true,
    },
    propertyValues: {
      type: Array,
      required: true,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    showPlaceholderIcon: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    restrictForeignEntitySelection: {
      type: Boolean,
      default: false,
    },
    disableLinks: {
      type: Boolean,
      default: false,
    },
    popup: {
      type: Boolean,
      default: false,
    },
    changeOnExit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'blur'],
  components: { SpaceSelector, UserPicker, LookupValue, NumberInput, TextInput, UrlInput },
  setup(props) {
    const { userLang } = useLoggedInUser();
    const { resourceSettings } = useResourceSettings();

    const spacesOrder = computed(() => resourceSettings.value.spaceOrder.map(({ uid }) => uid));
    const propertySvc = useProperties();
    const optionsOrder = computed(() => {
      const property = propertySvc.selectSingle(props.property.uid);
      return property.propertyOptionOrder;
    });
    return {
      spacesOrder,
      optionsOrder,
      userLang,
    };
  },
  data() {
    return {
      PROFILE,
      DateTime,
      fromISO,
      localValue: undefined,
    };
  },
  computed: {
    propertyLabel() {
      return textByLang(this.property.label, this.userLang);
    },
    icon() {
      return iconByType(this.property);
    },
    isTypeDate() {
      return this.property.type === propertyType.date;
    },
    isTypeSpace() {
      return this.property.type === propertyType.space;
    },
    isTypeOptions() {
      return this.property.type === propertyType.options;
    },
    isTypeNumber() {
      return this.property.type === propertyType.number;
    },
    isTypeText() {
      return this.property.type === propertyType.text;
    },
    isTypeUrl() {
      return this.property.type === propertyType.url;
    },
    isTypeSingleSelect() {
      return this.property.type === propertyType.singleSelect;
    },
    isTypeUser() {
      return this.property.type === propertyType.user;
    },
    isTypeLookup() {
      return this.property.type === propertyType.lookup;
    },
    isValueCollection() {
      return [propertyType.user, propertyType.space, propertyType.options].includes(this.property.type);
    },
    value() {
      const value = this.propertyValues.find((item) => item.property.uid === this.property.uid);
      if (value === undefined) {
        return emptyValue(this.property);
      }
      return value;
    },
    numberFormat() {
      if (!this.isTypeNumber) {
        return undefined;
      }
      return this.property.numberFormat;
    },
    options() {
      return this.property.options.map((o) => ({ ...o, icon: buildIconFromEntity(o) }));
    },
    numberValue() {
      return this.localValue;
    },
    textValue() {
      return this.localValue;
    },
    urlValue() {
      return this.localValue;
    },
    dateValue() {
      return this.localValue;
    },
    selectedOption() {
      return this.localValue;
    },
    selectedUsers() {
      return this.localValue;
    },
    selectedOptions() {
      return sortedPropertyElements(this.localValue, this.optionsOrder);
    },
    selectedSpaces() {
      return sortedPropertyElements(this.localValue, this.spacesOrder);
    },
  },
  methods: {
    focus() {
      if (this.isTypeDate) {
        this.$refs.input.open();
      }
      if (this.isTypeNumber) {
        this.$refs.input.open();
      }
      if (this.isTypeText) {
        this.$refs.input.open();
      }
      if (this.isTypeUrl) {
        this.$refs.input.open();
      }
      if (this.isTypeSpace) {
        this.$refs.input.show();
      }
      if (this.isTypeOptions) {
        this.$refs.input.show();
      }
      if (this.isTypeSingleSelect) {
        this.$refs.input.show();
      }
      if (this.isTypeUser) {
        this.$refs.input.show();
      }
    },
    maybeEmitChange(payload, force = false) {
      if (!force && this.changeOnExit) {
        return;
      }
      this.$emit('change', payload);
    },
    handleExit(changeMethod, value) {
      if (this.changeOnExit) {
        changeMethod(value, true);
      }
      this.$emit('blur');
    },
    changeSelectedOptions(value, force = false) {
      this.localValue = value;
      this.maybeEmitChange({ uid: this.value.uid, property: { uid: this.property.uid }, selectedOptions: value.map((v) => ({ uid: v.uid })) }, force);
    },
    changeSelectedOption(value, force = false) {
      this.localValue = value;
      let selectedOptions = [];
      if (value !== null) {
        selectedOptions = [{ uid: value.uid }];
      }
      this.maybeEmitChange({ uid: this.value.uid, property: { uid: this.property.uid }, selectedOptions }, force);
    },
    changeSelectedSpaces(value, force = false) {
      this.localValue = value;
      this.maybeEmitChange({ uid: this.value.uid, property: { uid: this.property.uid }, spaces: value.map((s) => ({ uid: s.uid })) }, force);
    },
    changeSelectedUser(value, force = false) {
      this.localValue = value;
      this.maybeEmitChange({ uid: this.value.uid, property: { uid: this.property.uid }, users: value.map((v) => ({ uid: v.uid })) }, force);
    },
    changeText(value, force = false) {
      this.localValue = value;
      this.maybeEmitChange({ uid: this.value.uid, property: { uid: this.property.uid }, text: value }, force);
    },
    changeUrl(value, force = false) {
      this.localValue = value;
      this.maybeEmitChange({ uid: this.value.uid, property: { uid: this.property.uid }, text: value }, force);
    },
    changeNumber(value, force = false) {
      this.localValue = value;
      this.maybeEmitChange({ uid: this.value.uid, property: { uid: this.property.uid }, number: value }, force);
    },
    changeDate(value, force = false) {
      this.localValue = value;
      this.maybeEmitChange({ uid: this.value.uid, property: { uid: this.property.uid }, timestamp: toISO(value) }, force);
    },
    exitNumber() {
      this.handleExit(this.changeNumber, this.numberValue);
    },
    exitText() {
      this.handleExit(this.changeText, this.textValue);
    },
    exitUrl() {
      this.handleExit(this.changeUrl, this.urlValue);
    },
    exitDate() {
      this.handleExit(this.changeDate, this.dateValue);
    },
    exitSingleSelect() {
      this.handleExit(this.changeSelectedOption, this.selectedOption);
    },
    exitUser() {
      this.handleExit(this.changeSelectedUser, this.selectedUsers);
    },
    exitOptions() {
      this.handleExit(this.changeSelectedOptions, this.selectedOptions);
    },
    exitSpace() {
      this.handleExit(this.changeSelectedSpaces, this.selectedSpaces);
    },
    resetLocalValue() {
      if (this.isTypeNumber) {
        if (isNullOrUndefined(this.value)) {
          return null;
        }
        return this.value.number;
      }
      if (this.isTypeText || this.isTypeUrl) {
        if (isNullOrUndefined(this.value)) {
          return '';
        }
        return this.value.text;
      }
      if (this.isTypeDate) {
        if (isNullOrUndefined(this.value) || isNullOrUndefined(this.value.timestamp)) {
          return null;
        }
        return this.value.timestamp;
      }
      if (this.isTypeSingleSelect) {
        if (isNullOrUndefined(this.value) || isNullOrUndefined(this.value.selectedOptions) || this.value.selectedOptions.length === 0) {
          return null;
        }
        return this.value.selectedOptions[0];
      }
      if (this.isTypeSpace) {
        if (isNullOrUndefined(this.value) || isNullOrUndefined(this.value.spaces)) {
          return [];
        }
        return this.value.spaces;
      }
      if (this.isTypeUser) {
        if (isNullOrUndefined(this.value) || isNullOrUndefined(this.value.users)) {
          return [];
        }
        return this.value.users;
      }
      if (this.isTypeOptions) {
        if (isNullOrUndefined(this.value) || isNullOrUndefined(this.value.selectedOptions)) {
          return [];
        }
        return this.value.selectedOptions;
      }
      return undefined;
    },
  },
  watch: {
    value(val) {
      if ((this.isValueCollection && equalP({ a: val, b: this.localValue }))
          || (!this.isValueCollection && isEqual(val, this.localValue))) {
        return;
      }
      this.localValue = this.resetLocalValue();
    },
  },
  created() {
    this.localValue = this.resetLocalValue();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
