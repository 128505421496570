<template>
  <m-dropdown
    v-model:value="shareDropdownVisible"
    :title="$t('shareDropdown.shareTitle')"
    placement="bottomCenter"
    :block="$store.state.breakpoint.smAndDown"
  >
    <component
      :is="$store.state.breakpoint.smAndDown ? 'm-card-item' : 'm-btn'"
      hide-border
      class="_action -share"
      small
      :icon="icon"
      @click="shareDropdownVisible = true"
    >
      {{ $t('general.share') }}
    </component>
    <template #overlay>
      <m-card
        class="_overlay"
        no-padding
      >
        <access-right-source
          :entity="{accessPolicy, accessRight, creator}"
          :entity-type="entityType"
        />
        <access-policy
          :value="accessPolicy"
          :creator="creator"
          :page-link="pageLink"
          :disabled="disabled"
          :loading="loading"
          :add-user-text="addUserText"
          :creator-description="creatorDescription"
          :access-types="accessTypes"
          @input="onUpdate"
          @close="shareDropdownVisible = false"
        >
          <template #after-access-scope>
            <slot name="after-access-policy" />
          </template>
          <template #before-buttons>
            <slot name="before-buttons" />
          </template>
        </access-policy>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import AccessPolicy from '@/components/access-policy/AccessPolicy.vue';
import AccessRightSource from '@/components/access-policy/AccessRightSource.vue';
import { accessPolicyType } from 'shared/constants.json';

export default {
  name: 'ShareDropdown',
  props: {
    accessPolicy: {
      type: Object,
      required: true,
    },
    accessRight: {
      type: String,
      default: undefined,
    },
    creator: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: true,
    },
    entityType: {
      type: String,
      default: '',
    },
    onUpdate: {
      type: Function,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    pageLink: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    addUserText: {
      type: String,
      default: '',
    },
    creatorDescription: {
      type: String,
      default: '',
    },
    accessTypes: {
      type: Array,
      default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
    },
  },
  components: { AccessRightSource, AccessPolicy },
  data() {
    return { shareDropdownVisible: false };
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
