<template>
  <m-content
    class="not-found-page"
    padding
  >
    <div class="_inner">
      <div class="_inner">
        <div class="_emoji">
          {{ emoji }}
        </div>
        <div class="_not-found-title">
          {{ title }}
        </div>
        <m-btn
          :to="to"
          color="primary"
        >
          {{ anchorText }}
        </m-btn>
      </div>
    </div>
  </m-content>
</template>

<script>
export default {
  name: 'NotFoundPage',
  props: {
    title: {
      type: String,
      default: '',
    },
    emoji: {
      type: String,
      default: '🧐',
    },
    to: {
      type: Object,
      required: true,
    },
    anchorText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .not-found-page {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: inherit;
    text-align: center;

    ._emoji {
      font-size: 5rem;
    }

    ._not-found-title {
      margin-top: 1.6rem;
      margin-bottom: 2rem;
      font-size: $font-size-6;
    }
  }
</style>
