<template>
  <overlay-spinner
    v-if="pdfExportLoading"
    :text="$t('general.exporting')"
  />
  <export-top-bar
    v-if="isPrint"
    :title="exportTitle"
  />
  <page-layout
    :class="['goal-insights', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
    :hide-top-bar="isPrint"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_header"
      >
        <template #actions>
          <m-dropdown
            :title="$t('general.share')"
          >
            <m-btn
              hide-border
              class="_action -share"
              small
            >
              {{ $t('general.share') }}
            </m-btn>
            <template #overlay>
              <m-card
                class="_overlay"
                padding-xs
              >
                <m-btn
                  v-clipboard:copy="pageLink"
                  v-clipboard:error="onError"
                  color="primary"
                  block
                >
                  {{ $t('accessPolicy.copyLink') }}
                </m-btn>
              </m-card>
            </template>
          </m-dropdown>
          <favorite-button
            v-if="!$store.state.breakpoint.smAndDown"
            :title-suggestion="titleSuggestion"
          />
          <m-tooltip
            placement="bottom"
          >
            <template #title>
              {{ helpButton.text }}
            </template>
            <m-btn
              icon="question-circle"
              :href="helpButton.link"
              target="_blank"
              hide-border
              fab
              small
            />
          </m-tooltip>
          <m-dropdown
            v-model:value="showActions"
            :title="$t('general.actions')"
            placement="bottomRight"
          >
            <m-btn
              icon="ellipsis"
              hide-border
              fab
              small
            />
            <template #overlay>
              <m-card
                list
                no-padding
              >
                <m-card-item
                  v-if="amountOfReports === 0"
                  icon="bell"
                  @click="createReport"
                >
                  {{ $t('dashboardDetails.scheduleReport') }}
                </m-card-item>
                <m-card-item
                  v-if="amountOfReports > 0"
                  icon="bell"
                  @click="navigateToReports"
                >
                  {{ $t('dashboardDetails.manageReports') }} ({{ amountOfReports }})
                </m-card-item>
                <m-card-item
                  icon="download"
                  @click="exportPDF"
                >
                  {{ $t('general.saveAsPDF') }}
                </m-card-item>
                <favorite-button
                  v-if="$store.state.breakpoint.smAndDown"
                  :title-suggestion="titleSuggestion"
                />
              </m-card>
            </template>
          </m-dropdown>
        </template>
      </page-top-bar>
    </template>
    <scroll-container>
      <m-content
        v-if="!isPrint"
        class="_content"
        padding-x="layout"
      >
        <editable-page-header
          :title="$t('navigation.dashboards')"
          disabled
          size="small"
        />
        <sub-menu-tabs
          :items="tabs"
          small
          light
          class="_tabs"
        />
      </m-content>
      <goal-insights-performance-tab
        v-if="isPerformance"
      />
      <goal-insights-health-tab
        v-if="isHealth"
      />
    </scroll-container>
    <m-dialog
      :value="showScheduleReport"
      :max-width="$modalSizes.lg"
      hide-footer
      no-padding
      :title="$t('reportsTable.scheduleReport')"
      @close="closeScheduleReport"
    >
      <report-editor
        :entity="reportToCreate"
        hide-dashboard-selector
        @cancel="closeScheduleReport"
        @close="closeScheduleReport"
      />
    </m-dialog>
  </page-layout>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import ExportTopBar from '@/components/dashboard/ExportTopBar.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import GoalInsightsHealthTab from '@/components/goal-insights/health/GoalInsightsHealthTab.vue';
import GoalInsightsPerformanceTab from '@/components/goal-insights/performance/GoalInsightsPerformanceTab.vue';
import OverlaySpinner from '@/components/OverlaySpinner.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ReportEditor from '@/components/report/ReportEditor.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import SubMenuTabs from '@/components/SubMenuTabs.vue';
import useExport from '@/composables/export/export';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useReport from '@/composables/report/report';
import useViewNavigator from '@/composables/saved-views/navigator';
import { DateTime } from 'luxon';
import { GOAL_INSIGHTS_HEALTH_VIEW, GOAL_INSIGHTS_PERFORMANCE_VIEW, VIEW } from '@/route-params';
import { RRule } from 'rrule';
import { buildIcon } from 'shared/lib/icon';
import { computed, ref } from 'vue';
import { featureFlag, printablePage, routeName, viewApplication } from 'shared/constants.json';
import { frontendRRuleToBackend } from '@/lib/rrule';
import { getQueryParam } from '@/lib/route';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'GoalInsights',
  components: {
    ReportEditor,
    OverlaySpinner,
    ExportTopBar,
    SubMenuTabs,
    ScrollContainer,
    EditablePageHeader,
    PageLayout,
    PageTopBar,
    GoalInsightsPerformanceTab,
    GoalInsightsHealthTab,
    FavoriteButton,
  },
  setup() {
    const { linkToView } = useViewNavigator();
    const { isPrint, saveAsPDF, pdfExportLoading } = useExport();
    const route = useRoute();
    const router = useRouter();
    const { loggedInUser } = useLoggedInUser();
    const currentView = computed(() => getQueryParam(route, VIEW));
    const isPerformance = computed(() => currentView.value === GOAL_INSIGHTS_PERFORMANCE_VIEW);
    const reportSvc = useReport();

    const printablePageType = computed(() => {
      if (isPerformance.value) {
        return printablePage.progressDashboard;
      }
      return printablePage.healthDashboard;
    });

    const amountOfReports = computed(() => reportSvc.entityList.value.filter((r) => {
      if (isPerformance.value) {
        return r.printable?.page === printablePage.progressDashboard;
      }
      return r.printable?.page === printablePage.healthDashboard;
    }).length);

    const showScheduleReport = ref(false);
    const reportToCreate = ref(null);
    const createReport = () => {
      const now = DateTime.local();
      const dtstart = DateTime.local(now.year, now.month, now.day, 9, 0, 0, 0);

      const rRule = new RRule({
        dtstart: dtstart.toJSDate(),
        tzid: DateTime.local().zoneName,
        freq: RRule.WEEKLY,
        byweekday: [now.weekday - 1],
        interval: 1,
        byminute: 0,
        bysecond: 0,
        byhour: dtstart.hour,
      });

      const rRuleStr = frontendRRuleToBackend(rRule);
      reportToCreate.value = {
        uid: 0,
        recipients: [loggedInUser.value.email],
        schedule: rRuleStr,
        printable: { page: printablePageType.value },
      };
      showScheduleReport.value = true;
    };

    const navigateToReports = () => {
      router.push({ name: routeName.scheduledReports });
    };
    const closeScheduleReport = () => {
      showScheduleReport.value = false;
    };

    return {
      linkToView,
      isPrint,
      saveAsPDF,
      pdfExportLoading,
      amountOfReports,
      isPerformance,
      currentView,
      createReport,
      navigateToReports,
      closeScheduleReport,
      showScheduleReport,
      reportToCreate,
    };
  },
  data() {
    return { pageLink: window.location, showActions: false };
  },
  computed: {
    exportTitle() {
      if (this.isPerformance) {
        return this.$t('navigation.goalInsightsPerformanceTitle');
      }
      return this.$t('navigation.goalInsightsHealthTitle');
    },
    tabs() {
      return [{
        title: this.$t('navigation.goalInsightsPerformance'),
        to: this.linkToView(GOAL_INSIGHTS_PERFORMANCE_VIEW, viewApplication.insight),
        view: GOAL_INSIGHTS_PERFORMANCE_VIEW,
        active: this.currentView === GOAL_INSIGHTS_PERFORMANCE_VIEW,
      }, {
        title: this.$t('navigation.goalInsightsHealth'),
        to: this.linkToView(GOAL_INSIGHTS_HEALTH_VIEW, viewApplication.insightHealth),
        view: GOAL_INSIGHTS_HEALTH_VIEW,
        active: this.currentView === GOAL_INSIGHTS_HEALTH_VIEW,
        planTag: { featureFlag: featureFlag.healthDashboard },
      }];
    },
    isHealth() {
      return this.currentView === GOAL_INSIGHTS_HEALTH_VIEW;
    },
    titleSuggestion() {
      switch (true) {
        case this.isPerformance:
          return `${this.$t('navigation.dashboards')} - ${this.viewTitle}`;
        case this.isHealth:
          return `${this.$t('navigation.dashboards')} - ${this.viewTitle}`;
        default:
          return '';
      }
    },
    viewTitle() {
      switch (true) {
        case this.isPerformance:
          return this.$t('navigation.goalInsightsPerformance');
        case this.isHealth:
          return this.$t('navigation.goalInsightsHealth');
        default:
          return '';
      }
    },
    helpButton() {
      switch (true) {
        case this.isPerformance:
          return {
            link: this.$t('dashboards.progressDashboard.helpLink'),
            text: this.$t('dashboards.progressDashboard.helpText'),
          };
        case this.isHealth:
          return {
            link: this.$t('dashboards.healthDashboard.helpLink'),
            text: this.$t('dashboards.healthDashboard.helpText'),
          };
        default:
          return '';
      }
    },
    breadcrumbs() {
      return [
        {
          title: this.$t('navigation.dashboards'),
          icons: [{ value: buildIcon('AppstoreOutlined') }],
          disabled: false,
          to: { name: routeName.dashboards },
        },
        {
          title: this.viewTitle,
          disabled: true,
        },
      ];
    },
    printablePageType() {
      if (this.isPerformance) {
        return printablePage.progressDashboard;
      }
      return printablePage.healthDashboard;
    },
  },
  methods: {
    onError() {
      this.$showSnackbar({ color: 'error', message: this.$t('error.duringCopying') });
    },
    exportPDF() {
      this.showActions = false;
      if (this.$route.query.viewId !== undefined && parseInt(this.$route.query.viewId, 10) !== 0) {
        this.saveAsPDF({ page: this.printablePageType, savedView: { uid: parseInt(this.$route.query.viewId, 10) } });
        return;
      }
      this.saveAsPDF({ page: this.printablePageType });
    },
    initRoute() {
      if (![GOAL_INSIGHTS_PERFORMANCE_VIEW, GOAL_INSIGHTS_HEALTH_VIEW].includes(this.$route.query.view)) {
        this.$router.push({ ...this.$route, query: { view: GOAL_INSIGHTS_PERFORMANCE_VIEW } });
      }
    },
  },
  watch: {
    currentView() {
      this.initRoute();
    },
  },
  created() {
    this.initRoute();
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
.goal-insights {
  ._content {
    position: sticky;
    left: 0;
    width: 100%;

    ._tabs {
      margin: .5rem 0 2rem;
      overflow: auto;
    }
  }
}

._overlay {
  width: 30rem;
}
</style>
