<template>
  <div class="update-template-editor">
    <page-top-bar>
      <template #left>
        {{ updateTemplate.title }}
      </template>
      <template
        v-if="id !== 0"
        #actions
      >
        <share-dropdown
          :access-policy="updateTemplate.accessPolicy"
          :creator="updateTemplate.creator"
          entity-type="updateTemplate"
          icon="lock"
          :on-update="updateAccessPolicy"
          :add-user-text="$t('updateTemplateEditor.addUser')"
          :creator-description="$t('updateTemplateEditor.creatorDescription')"
          :disabled="!canEdit"
          :loading="updateUpdateTemplateLoading"
        />
      </template>
    </page-top-bar>
    <div class="_alert">
      {{ $t('updateTemplateEditor.alert') }}
    </div>
    <m-content
      boxed-small
      padding
      padding-x="layout"
    >
      <update-template
        :update-template="updateTemplate"
        :can-edit="canEdit"
        :on-update="update"
        @submit="$emit('close')"
      />
    </m-content>
  </div>
</template>

<script>
import PageTopBar from '@/components/page/PageTopBar.vue';
import ShareDropdown from '@/components/access-policy/ShareDropdown.vue';
import UpdateTemplate from '@/components/updates/UpdateTemplate.vue';
import useDebounce from '@/composables/debounce';
import useUpdateTemplates from '@/composables/update-templates/update-templates';
import { accessPolicyType } from 'shared/constants.json';

export default {
  name: 'UpdateTemplateEditor',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  emits: ['updated', 'close'],
  components: {
    PageTopBar,
    UpdateTemplate,
    ShareDropdown,
  },
  setup() {
    const { debounce } = useDebounce();
    const updateTemplatesSvc = useUpdateTemplates();

    return {
      debounce,
      updateTemplates: updateTemplatesSvc.updateTemplates,
      updateUpdateTemplate: updateTemplatesSvc.updateUpdateTemplate,
      updateUpdateTemplateAccessPolicy: updateTemplatesSvc.updateUpdateTemplateAccessPolicy,
      updateUpdateTemplateLoading: updateTemplatesSvc.updateUpdateTemplateLoading,
      selectSingle: updateTemplatesSvc.selectSingle,
    };
  },
  computed: {
    updateTemplate() {
      const template = this.selectSingle(this.id);
      if (template === undefined) {
        return {
          title: '',
          icon: '',
        };
      }
      return template;
    },
    canEdit() {
      return [accessPolicyType.full, accessPolicyType.write].includes(this.updateTemplate.accessRight);
    },
  },
  methods: {
    update(value, debounce = false, options = {}) {
      if (!this.canEdit) {
        return;
      }

      const updateFn = () => this.updateUpdateTemplate({ ...this.updateTemplate, ...value }, { ...options, ignoreResponse: false }).then(() => {
        this.$emit('updated');
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      if (debounce) {
        this.debounce(updateFn, 700);
        return;
      }
      updateFn();
    },
    updateAccessPolicy(value) {
      this.update({ accessPolicy: value }, false, { optimistic: false });
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .update-template-editor {
    ._alert {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5rem;
      margin-top: 1rem;
      color: $font-color-secondary;
      background-color: map_get($yellow, 'lighten-4');
    }
  }
</style>
