<template>
  <m-dropdown
    v-if="property.type === 'description' || property.edgeName === 'description'"
    ref="input"
    placement="onTopLeft"
    block
    :title="propertyLabel"
    :disabled="disabled"
    :match-trigger-width="matchTriggerWidth"
    @hide="$emit('blur')"
    @click.stop
  >
    <m-focusable
      :class="['goal-property-form-item _editor-content', wrap ? '-wrap' : '', { '-hide-hover': hideHover }]"
      :hide-border="hideBorder"
      :small="small"
      full-width
      :hide-hover="hideHover"
      :read-only="readOnly"
      :disabled="disabled"
      :m-style="mStyle"
    >
      <m-editor
        :initial-value="description"
        disabled
        :allowed-content="allowedDescriptionContent"
        :placeholder="placeholder"
        light-placeholder
        :wrap="wrap"
        :default-font-size="resolveStyles(mStyle).fontSize"
      />
    </m-focusable>
    <template #overlay>
      <m-card
        no-padding
        :style="matchTriggerWidth ? { } : { width: '30rem' }"
        border-radius="small"
      >
        <m-focusable
          hide-border
          hide-hover
          hide-placeholder
          full-width
          :class="['goal-property-form-item _editor-content', wrap ? '-wrap' : '']"
        >
          <m-editor
            :initial-value="description"
            :allowed-content="allowedDescriptionContent"
            :placeholder="placeholder"
            light-placeholder
            wrap
            full-width
            auto-focus
            :default-font-size="resolveStyles(mStyle).fontSize"
            :style="{ width: '100%' }"
            @input="updateDescription"
          />
        </m-focusable>
      </m-card>
    </template>
  </m-dropdown>
  <metric-form
    v-else-if="property.type === 'progressMeasurement'"
    :m-style="mStyle"
    :goal="goal"
    :goal-children="goalChildren"
    :hide-hover="hideHover"
    :read-only="readOnly"
    :disabled="disabled"
  />
  <m-tooltip
    v-else-if="property.type === 'parents'"
    :disabled="!goalPickerService.disabled.value"
    placement="left"
    :mouse-enter-delay="0.5"
  >
    <goal-picker-v2
      v-model:value="selectedParentIds"
      :small="small"
      :excluded-goal-ids="[goal.uid]"
      :disabled-condition="goalPickerService.disabledCondition"
      :hide-placeholder="hidePlaceholder"
      :disabled="goalPickerService.disabled.value"
      :multiple="goalPickerService.multiAlignment"
      :read-only="readOnly"
      :default-view="goalPickerService.defaultView"
      :view-application="goalPickerService.viewApplication"
      :base-filter="goalPickerService.filter"
      :hide-hover="hideHover"
      :hide-border="hideBorder"
      :full-width="fullWidth"
      :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
      :wrap="wrap"
      :m-style="mStyle"
      placement="onTopCenter"
      show-create-view
      show-views-selector
      show-input-field
      :initial-selected-cycles="goal.goalCycle"
      @hide="updateProperties(selectedParentIds)"
    />
    <template #title>
      {{ $t('goalProperties.noParentAllowed') }}
    </template>
  </m-tooltip>
  <goal-cycle-selector
    v-else-if="property.type === 'goalCycle' || property.edgeName === 'goalCycle'"
    ref="input"
    :small="small"
    :value="goal.goalCycle"
    :hide-border="hideBorder"
    show-search
    keep-open-on-click
    :wrap="wrap"
    :full-width="fullWidth"
    :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
    :read-only="readOnly"
    :disabled="disabled"
    :placeholder="placeholder"
    :hide-placeholder="hidePlaceholder"
    :hide-hover="hideHover"
    :m-style="mStyle"
    multiple
    placement="onTopLeft"
    @input="updateProperties"
    @close="$emit('blur')"
  />
  <property-form-item
    v-else
    ref="input"
    :property="property"
    :property-values="goal.properties"
    :show-placeholder-icon="showPlaceholderIcon"
    :placeholder="placeholder"
    :hide-placeholder="hidePlaceholder"
    :disabled="disabled"
    :read-only="readOnly"
    :wrap="wrap"
    :small="small"
    :full-width="fullWidth"
    :hide-hover="hideHover"
    :m-style="mStyle"
    clearable
    :hide-border="hideBorder"
    :popup="popup"
    :match-trigger-width="matchTriggerWidth"
    restrict-foreign-entity-selection
    :change-on-exit="changeOnExit"
    @change="updateProperties"
    @blur="$emit('blur')"
  />
</template>

<script>
import GoalCycleSelector from '@/components/goal/cycle/GoalCycleSelector.vue';
import GoalPickerV2 from '@/components/goal/GoalPickerV2.vue';
import MEditor from '@/components/editor/MEditor.vue';
import MetricForm from '@/components/goal/MetricForm.vue';
import PropertyFormItem from '@/components/PropertyFormItem.vue';
import useAccess from '@/composables/access/access';
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import { editorNodeType, featureFlag } from 'shared/constants.json';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { isEqual } from 'lodash-es';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'GoalPropertyFormItem',
  props: {
    ...mStyleProps,
    property: {
      type: Object,
      required: true,
    },
    goal: {
      type: Object,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
    goalChildren: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showPlaceholderIcon: {
      type: Boolean,
      default: false,
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    popup: {
      type: Boolean,
      default: false,
    },
    updateProperties: {
      type: Function,
      required: true,
    },
    goalPickerService: {
      type: Object,
      required: true,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MEditor,
    MetricForm,
    GoalPickerV2,
    GoalCycleSelector,
    PropertyFormItem,
  },
  setup() {
    const { accountHasFeature } = useAccess();

    const allowedDescriptionContent = [
      editorNodeType.bulletList,
      editorNodeType.orderedList,
    ];
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedDescriptionContent.push(editorNodeType.image, editorNodeType.file);
    }

    const { userLang } = useLoggedInUser();
    const { spaceProperty } = useProperties();

    const { debounce } = useDebounce();
    return { debounce, allowedDescriptionContent, spaceProperty, userLang };
  },
  emits: ['blur'],
  data() {
    return {
      description: null,
      selectedParentIds: [],
    };
  },
  computed: {
    propertyLabel() {
      return textByLang(this.property.label, this.userLang);
    },
    changeOnExit() {
      return this.property.uid === this.spaceProperty.uid;
    },
    goalDescription() {
      return this.goal.description;
    },
    goalParents() {
      return this.goal.parents;
    },
  },
  methods: {
    updateDescription(value) {
      this.description = value;
      const update = () => this.updateProperties(value);
      this.debounce(update, 700);
    },
    resolveStyles,
    focus() {
      if (this.property.type === goalConfig.edges.goalCycle || this.property.edgeName === goalConfig.edges.goalCycle) {
        this.$refs.input.show();
        return;
      }
      if (this.property.type === goalConfig.edges.description || this.property.edgeName === goalConfig.edges.description) {
        this.$refs.input.open();
        return;
      }
      this.$refs.input.focus();
    },
  },
  watch: {
    goalDescription(val) {
      if (isEqual(val, this.description)) {
        return;
      }
      this.description = val;
    },
    goalParents(val) {
      if (isEqual(val, this.selectedParentIds)) {
        return;
      }
      this.selectedParentIds = this.goal.parents.map((g) => g.uid);
    },
  },
  created() {
    this.selectedParentIds = this.goal.parents.map((g) => g.uid);
    this.description = this.goal.description;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-property-form-item._editor-content {
    padding: .3rem .8rem;

    &.-hide-hover {
      padding: .6rem .8rem;
    }

    &.-wrap {
      height: 100%;
      max-height: 40rem;
      overflow: auto;
      display: block;
    }

    .m-editor {
      width: 100%;

      p.is-editor-empty {
        min-height: 0;
        padding: 0;
      }
    }
  }
</style>
